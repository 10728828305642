import React from 'react';
import { compose } from 'redux';

import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';

import { PrimaryButton, NamedLink } from '../../../../components';
import { REGISTRATING_STEPS, USER_ROLES } from '../../constants';
import css from './CPRegistration.module.css';

const { CP_INFORMATION_AND_FORM_DOWNLOADING } = REGISTRATING_STEPS;
const { COMMUNITY_PARTNER } = USER_ROLES;

export const CPRegistrationSuccessComponent = ({ intl }) => {
  const email = intl.formatMessage({ id: 'CPRegistrationSuccess.adminEmail' });
  return (
    <div>
      <div className={css.thankYouBody}>
        <p className={css.heading}>
          <FormattedMessage id="CPRegistrationSuccess.thankYou" />
        </p>

        <a href={`mailto:${email}`}>
          <FormattedMessage id="CPRegistrationSuccess.adminEmail" />
        </a>
      </div>

      <NamedLink className={css.link} name="LandingPage">
        <PrimaryButton className={css.downloadBtn}>
          <FormattedMessage id="CPRegistrationSuccess.backHome" />
        </PrimaryButton>
      </NamedLink>

      <NamedLink
        className={css.goBack}
        name="SignupPage"
        to={{
          state: {
            registerFlow: {
              role: COMMUNITY_PARTNER,
              step: CP_INFORMATION_AND_FORM_DOWNLOADING,
            },
          },
        }}
      >
        <FormattedMessage id="CPRegistrationSuccess.goBackText" />
      </NamedLink>
    </div>
  );
};

const CPRegistrationSuccess = compose(injectIntl)(CPRegistrationSuccessComponent);
CPRegistrationSuccess.displayName = 'CPRegistrationSuccess';

CPRegistrationSuccessComponent.propTypes = {
  intl: intlShape.isRequired,
};

export default CPRegistrationSuccess;
