import React from 'react';
import { compose } from 'redux';

import { FormattedMessage, injectIntl } from '../../../../util/reactIntl';
import { NamedLink, PrimaryButton } from '../../../../components';
import { REGISTRATING_STEPS, USER_ROLES } from '../../constants';
import css from './SelectAccountTypeCard.module.css';

const { DONOR, COMMUNITY_PARTNER } = USER_ROLES;
const { DONOR_DETAILS, CP_INFORMATION_AND_FORM_DOWNLOADING } = REGISTRATING_STEPS;

const SelectAccountTypeCardComponent = props => {
  const { intl } = props;
  const communityOrganisationPageLink = intl.formatMessage({
    id: 'SignupForm.communityOrganisation',
  });

  const donateLink = intl.formatMessage({
    id: 'SignupForm.WhatToDonateLink',
  });

  return (
    <>
      <h3 className={css.chooseAccountHeader}>
        <FormattedMessage id="SignupForm.chooseAccountText" />
      </h3>

      <div className={css.donorContainer}>
        <NamedLink
          className={css.link}
          name="SignupPage"
          to={{ state: { registerFlow: { role: DONOR, step: DONOR_DETAILS } } }}
        >
          <PrimaryButton className={css.signupBtn}>
            <FormattedMessage id="SignupForm.donorBtnText" />
          </PrimaryButton>
        </NamedLink>
        <p>
          <FormattedMessage id="SignupForm.donorDescriptionText" />
        </p>
        <NamedLink className={css.link} name="CMSPage" params={{ pageId: donateLink }}>
          <FormattedMessage id="SignupForm.cannotDonateText" />
        </NamedLink>
      </div>
      <div className={css.cpContainer}>
        <NamedLink
          className={css.link}
          name="SignupPage"
          to={{
            state: {
              registerFlow: {
                role: COMMUNITY_PARTNER,
                step: CP_INFORMATION_AND_FORM_DOWNLOADING,
              },
            },
          }}
        >
          <PrimaryButton className={css.signupBtn}>
            <FormattedMessage id="SignupForm.cpBtnText" />
          </PrimaryButton>
        </NamedLink>
        <p>
          <FormattedMessage id="SignupForm.cpDescriptionText" />
        </p>
        <NamedLink
          className={css.link}
          name="CMSPage"
          params={{ pageId: communityOrganisationPageLink }}
        >
          <FormattedMessage id="SignupForm.cpTermsText" />
        </NamedLink>
      </div>
    </>
  );
};

const SelectAccountTypeCard = compose(injectIntl)(SelectAccountTypeCardComponent);

export default SelectAccountTypeCard;
