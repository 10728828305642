import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { injectIntl } from '../../../util/reactIntl';
import { USER_ROLES } from '../constants';
import SignupForm from './SignupForm/SignupForm';
import SelectAccountTypeCard from './SelectAccountTypeCard/SelectAccountTypeCard';
import { REGISTRATING_STEPS } from '../constants';
import CPRegistration from './CPRegistration/CPRegistration';
import CPRegistrationSuccess from './CPRegistration/CPRegistrationSuccess';

const { DONOR, COMMUNITY_PARTNER } = USER_ROLES;
const { CP_INFORMATION_AND_FORM_DOWNLOADING, CP_THANKS_FOR_DOWNLOADING } = REGISTRATING_STEPS;

export const SignupPanelComponent = props => {
  const { location, ...restProps } = props;
  const role = location.state?.registerFlow?.role;

  if (role === DONOR) {
    return <SignupForm {...restProps} />;
  }

  if (role === COMMUNITY_PARTNER) {
    switch (location.state?.registerFlow?.step) {
      case CP_INFORMATION_AND_FORM_DOWNLOADING:
        return <CPRegistration />;
      case CP_THANKS_FOR_DOWNLOADING:
        return <CPRegistrationSuccess />;
      default:
        <SelectAccountTypeCard />;
    }
  }

  return <SelectAccountTypeCard />;
};

const SignupPanel = compose(
  withRouter,
  injectIntl
)(SignupPanelComponent);

export default SignupPanel;
