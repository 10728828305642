import React from 'react';
import { compose } from 'redux';

import { injectIntl } from '../../../util/reactIntl';

import { Modal } from '../../../components';
import css from '../AuthenticationPage.module.css';

export const TermsModalComponent = props => {
  const { content, heading, isOpen, handleClose, handleManageDisableScrolling } = props;
  return (
    <Modal
      id="AuthenticationPage.tos"
      isOpen={isOpen}
      onClose={handleClose}
      usePortal
      onManageDisableScrolling={handleManageDisableScrolling}
    >
      <div className={css.termsWrapper}>
        <h2 className={css.termsHeading}>{heading}</h2>
        {content}
      </div>
    </Modal>
  );
};

const TermsModal = compose(injectIntl)(TermsModalComponent);

export default TermsModal;
