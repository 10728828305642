import React from 'react';
import { compose } from 'redux';

import { FormattedMessage, injectIntl, intlShape } from '../../../../util/reactIntl';
import { pathByRouteName } from '../../../../util/routes';

import { PrimaryButton, NamedLink } from '../../../../components';
import { REGISTRATING_STEPS, USER_ROLES } from '../../constants';
import css from './CPRegistration.module.css';
import routeConfiguration from '../../../../routing/routeConfiguration';
import { withRouter } from 'react-router-dom';

const { CP_THANKS_FOR_DOWNLOADING } = REGISTRATING_STEPS;
const { COMMUNITY_PARTNER } = USER_ROLES;

const content = [
  'CPRegistration.listItem1',
  'CPRegistration.listItem2',
  'CPRegistration.listItem3',
  'CPRegistration.listItem4',
];

export const CPRegistrationComponent = ({ intl, history }) => {
  const communityOrganisationPageLink = intl.formatMessage({
    id: 'CPRegistration.CommunityOrganisationLink',
  });

  const email = intl.formatMessage({ id: 'CPRegistration.emailAddress' });
  const mailToEmail = (
    <a href={`mailto:${email}`}>
      <FormattedMessage id="CPRegistration.emailAddress" />
    </a>
  );

  const communityOrgObligation = intl.formatMessage({ id: 'CPRegistration.listItem5' });

  const handleDownloadBtnClick = () => {
    const fileLink = intl.formatMessage({ id: 'CPRegistration.cpRegistrationFormLink' });
    window.open(fileLink);
    history.push(pathByRouteName('SignupPage', routeConfiguration()), {
      registerFlow: {
        role: COMMUNITY_PARTNER,
        step: CP_THANKS_FOR_DOWNLOADING,
      },
    });
  };

  return (
    <div>
      <h3 className={css.heading}>
        <FormattedMessage id="CPRegistration.heading" />
      </h3>
      <div>
        <p>
          <FormattedMessage id="CPRegistration.description" />
        </p>
        <ul className={css.obligationList}>
          {content.map(id => {
            const item = intl.formatMessage({ id }, { mailToEmail });
            return item !== id ? (
              <li className={css.obligationItem} key={id}>
                {item}
              </li>
            ) : null;
          })}
          <li className={css.obligationItem}>
            <NamedLink name="CMSPage" params={{ pageId: communityOrganisationPageLink }}>
              {communityOrgObligation}
            </NamedLink>
          </li>
        </ul>
      </div>

      <PrimaryButton className={css.downloadBtn} onClick={handleDownloadBtnClick}>
        <FormattedMessage id="CPRegistration.downloadBtn" />
      </PrimaryButton>

      <NamedLink name="SignupPage" className={css.goBack}>
        <FormattedMessage id="CPRegistration.goBackText" />
      </NamedLink>
    </div>
  );
};

const CPRegistration = compose(injectIntl)(CPRegistrationComponent);
CPRegistration.displayName = 'CPRegistration';

CPRegistrationComponent.propTypes = {
  intl: intlShape.isRequired,
};

export default withRouter(CPRegistration);
